<template>
  <div class="card">
    <div class="card-body">
      <el-row>
        <el-col :span="12">
          <p class="text-uppercase text-m font-weight-bold">
            {{ t('landingPageSignup.hotelInfoSection') }}
          </p>
        </el-col>
        <el-col :span="12">
          <el-button class="text-uppercase ms-auto fr" type="primary" @click="handleUpdateTenant">
            {{ $t('update') }}
          </el-button>
        </el-col>
      </el-row>
      <el-row class="hotel-details-form">
        <el-form
          ref="hotelDetailsRef"
          :model="base.hotelForm"
          label-width="auto"
          :rules="base.hotelDetailsRules"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item :label="$t('hotelName')" prop="name">
                <el-input v-model="base.hotelForm.name" size="large" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('mobileNumber')" prop="mobile">
                <el-input
                  v-model="base.hotelForm.mobile"
                  maxlength="10"
                  size="large"
                  autocomplete="off"
                  @input="
                    () => {
                      base.hotelForm.mobile = base.hotelForm.mobile.replace(/[^0-9]/g, '')
                    }
                  "
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('email')" prop="emailId">
                <el-input v-model="base.hotelForm.emailId" size="large" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('address')" prop="address">
                <el-input v-model="base.hotelForm.address" size="large" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('website')" prop="website">
                <el-input v-model="base.hotelForm.website" size="large" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('checkInTime')" prop="checkInTime">
                <el-time-picker
                  v-model="base.hotelForm.checkInTime"
                  arrow-control
                  size="large"
                  class="w-100"
                  :placeholder="$t('common.pickATime')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('checkOutTime')" prop="checkOutTime">
                <el-time-picker
                  v-model="base.hotelForm.checkOutTime"
                  arrow-control
                  size="large"
                  class="w-100"
                  :placeholder="$t('common.pickATime')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('gstNumber')" prop="config.gstNumber">
                <el-input
                  v-model="base.hotelForm.config.gstNumber"
                  size="large"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item :label="$t('hotelLogo')" prop="config.hotelLogo.base64String">
                <el-col v-if="base.hotelForm.config.hotelLogo?.base64String">
                  <img
                    :src="`data:${base.hotelForm.config.hotelLogo?.mimeType};base64,${base.hotelForm.config.hotelLogo?.base64String}`"
                    width="150"
                  />
                </el-col>
                <el-col v-if="base.hotelForm.config.hotelLogo?.base64String">
                  <el-upload
                    class="upload-demo mt10"
                    :show-file-list="false"
                    :on-remove="handleRemove"
                    :http-request="handleUploadRequest"
                    :on-change="handleChange"
                  >
                    <el-button type="primary" class="w-100">Click to Re-Upload</el-button>
                  </el-upload>
                  <el-button
                    class="mt10 download-button"
                    type="default"
                    @click="downloadBase64File"
                  >
                    {{ $t('reservations.clickToDownload') }}
                  </el-button>
                </el-col>
                <el-upload
                  v-if="!base.hotelForm.config.hotelLogo?.base64String"
                  class="upload-demo w-100"
                  drag
                  :show-file-list="false"
                  :on-remove="handleRemove"
                  :http-request="handleUploadRequest"
                  :on-change="handleChange"
                >
                  <i class="fa-solid fa-cloud-arrow-up upload-icon-custom"></i>
                  <div class="el-upload__text">
                    {{ $t('reservations.dropFileHere') }}
                    <em>{{ $t('reservations.clickToUpload') }}</em>
                  </div>
                  <template #tip>
                    <el-row>
                      <el-col :span="12">
                        <div class="el-upload__tip text-sm">{{ $t('hotelDetails.uploadTip') }}</div>
                      </el-col>
                    </el-row>
                  </template>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item :label="$t('defaultLanguage')">
                <el-select
                  v-model="base.hotelForm.config.defaultLanguage"
                  value-key="name"
                  :placeholder="$t('common.select')"
                  size="large"
                  filterable
                >
                  <el-option
                    v-for="item in base.languages"
                    :key="item.name"
                    :label="item.displayName"
                    :value="item.name"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import TenantSvc from '@/services/tenants'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import constants from '@/constants'
import { ElNotification } from 'element-plus'
// import _ from 'lodash'
import { useI18n } from 'vue-i18n'

export default {
  name: 'HotelsDetailsForm',
  setup() {
    const { t } = useI18n()
    const hotelDetailsRef = ref(null)
    const base = reactive({
      hotelForm: {
        name: '',
        emailId: '',
        mobile: '',
        website: '',
        address: '',
        checkInTime: '',
        checkOutTime: '',
        config: {
          gstNumber: '',
          hotelLogo: {},
          defaultLanguage: 'en-US'
        }
      },
      hotelDetailsRules: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        emailId: [{ required: true, message: ' ', trigger: 'blur' }],
        mobile: [{ required: true, message: ' ', trigger: 'blur' }],
        website: [{ required: true, message: ' ', trigger: 'blur' }],
        address: [{ required: true, message: ' ', trigger: 'blur' }],
        checkInTime: [{ required: true, message: ' ', trigger: 'blur' }],
        checkOutTime: [{ required: true, message: ' ', trigger: 'blur' }],
        'config.gstNumber': [{ required: true, message: ' ', trigger: 'blur' }],
        // 'config.hotelLogo.base64String': [{ required: true, message: ' ', trigger: 'blur' }],
        'config.defaultLanguage': [{ required: true, message: ' ', trigger: 'blur' }]
      },
      titleOptions: constants.TITLE_OPTIONS,
      genderOptions: constants.GENDER_OPTIONS,
      countries: constants.COUNTRIES,
      states: [],
      languages: constants.LANGUAGES
    })

    onMounted(() => {
      fetchTenants()
    })

    const fetchTenants = () => {
      const options = AuthSvc.getOptions()
      const code = AuthSvc.getTenant()
      const query = { code }
      TenantSvc.find(options, query, undefined, 1, 1)
        .then(response => {
          console.log('response.data', response.data)
          const hotelForm = response.data.records[0]
          if (!hotelForm.config) {
            hotelForm.config = {
              gstNumber: '',
              hotelLogo: {},
              defaultLanguage: 'en-US'
            }
          }
          base.hotelForm = hotelForm
        })
        .catch(error => {
          console.log('HotelsTable fetchTenants error => ', error)
          common.showErrors(error)
        })
    }

    const handleRemove = () => {
      base.hotelForm.config.hotelLogo = {}
    }

    const handleUploadRequest = () => {
      console.log('uploading..')
    }

    const handleChange = file => {
      base.loading = true
      convertToBase64(file.raw)
    }

    const convertToBase64 = file => {
      if (!file) {
        base.loading = false
        // this.$message.error('Please select a file first!')
        return
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1] // Extract Base64 part
        // console.log('base64String', base64String)
        base.hotelForm.config.hotelLogo = {
          base64String,
          name: file.name,
          size: file.size,
          mimeType: file.type
        }
        base.loading = false
      }
      reader.onerror = () => {
        // this.$message.error('Error reading file')
        base.loading = false
      }

      reader.readAsDataURL(file)
    }

    const downloadBase64File = () => {
      if (base.hotelForm.config.hotelLogo?.base64String) {
        const base64String = `data:${base.hotelForm.config.hotelLogo.mimeType};base64,${base.hotelForm.config.hotelLogo.base64String}`
        // Create a temporary anchor element
        const link = document.createElement('a')

        // Set the href to the Base64 string and specify the file type in the data URL
        link.href = base64String
        link.download = base.hotelForm.config.hotelLogo.name // Set the desired file name

        // Append the link to the document and trigger a click event to download
        document.body.appendChild(link)
        link.click()

        // Remove the link after download
        document.body.removeChild(link)
      }
    }

    const handleUpdateTenant = () => {
      hotelDetailsRef.value.validate(valid => {
        if (valid) {
          const options = AuthSvc.getOptions()
          TenantSvc.save(options, base.hotelForm)
            .then(response => {
              console.log('response.data', response.data)
              ElNotification({
                message: 'Hotel Details updated successfully',
                type: 'success'
              })
            })
            .catch(error => {
              console.log('HotelsTable fetchTenants error => ', error)
              common.showErrors(error)
            })
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    return {
      t,
      base,
      hotelDetailsRef,
      handleRemove,
      handleUploadRequest,
      handleChange,
      downloadBase64File,
      handleUpdateTenant
    }
  }
}
</script>

<style>
.hotel-details-form {
  height: calc(100vh - 190px) !important;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 10px;
}
</style>
