'use strict'
import { jwtDecode } from 'jwt-decode'
import _ from 'lodash'
import constants from '@/constants'
import LocalStorageSvc from './localStorage'
const to = require('await-to-js').default
const baseAxiosClient = require('./baseAxiosClient')

const URL = constants.BASE_URL + 'auth/users'
const AUTH_URL = constants.BASE_URL + 'auth'

const tokenCode = '_hms_token'
const currentRoleCode = '_hms_currentRole'

class Auth {
  static async signup(payload) {
    const signupURL = AUTH_URL + '/signUp'
    const client = baseAxiosClient.getClient()
    return client.post(signupURL, payload)
  }

  static async login(payload) {
    const loginURL = URL + '/login'
    const client = baseAxiosClient.getClient()
    const [error, response] = await to(client.post(loginURL, payload))
    if (error) {
      throw error
    } else {
      const result = response.data
      if (result) {
        LocalStorageSvc.setItem(tokenCode, result.authToken)
        LocalStorageSvc.setItem(currentRoleCode, this.getDefaultRole())
        return result
      } else {
        throw new Error()
      }
    }
  }

  static getDefaultRole() {
    const token = this.getJWTToken()
    const decoded = jwtDecode(token)
    if (_.isEmpty(decoded.defaultRole)) {
      return this.getRoles()[0]
    } else {
      return decoded.defaultRole
    }
  }

  static getFullname() {
    const token = this.getJWTToken()
    if (token) {
      const decoded = jwtDecode(token)
      return `${decoded.firstName} ${decoded.lastName}`
    } else {
      return ''
    }
  }

  static getUserDetails() {
    const token = this.getJWTToken()
    const decoded = jwtDecode(token)
    return decoded
  }

  static getIsPlatformAdministrator() {
    const token = this.getJWTToken()
    if (token) {
      const decoded = jwtDecode(token)
      return decoded?.isPlatformAdministrator
    } else {
      return false
    }
  }

  static getTenant() {
    const token = this.getJWTToken()
    if (token) {
      const decoded = jwtDecode(token)
      return decoded?.tenant
    }
  }

  static getOptions(version) {
    const options = {
      authToken: this.getToken(),
      role: this.getCurrentRole(),
      headers: {
        'x-version': version || 'v1'
      }
    }
    return options
  }

  static getToken() {
    let token = ''
    const jwtToken = this.getJWTToken()
    if (jwtToken) {
      token = `Bearer ${jwtToken}`
    }
    return token
  }

  static getJWTToken() {
    return LocalStorageSvc.getItem(tokenCode)
  }

  static getTokenExpiry() {
    const token = this.getJWTToken()
    if (token) {
      const decoded = jwtDecode(token)
      return decoded.exp
    } else {
      return null
    }
  }

  static getCurrentRole() {
    return LocalStorageSvc.getItem(currentRoleCode)
  }
}

export default Auth
