<template>
  <div v-loading.fullscreen.lock="base.loading" class="card">
    <div class="card-header px-2 pb-0">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="base.search.text"
            :placeholder="$t('common.inputPlaceholder')"
            clearable
            class="input-with-select"
            @keyup.enter="onSearch"
          >
            <template #prepend>
              <el-select
                v-model="base.search.key"
                :placeholder="$t('common.select')"
                style="width: 150px"
              >
                <el-option :label="$t('invoiceNumber')" value="invoiceNumber" />
              </el-select>
            </template>
            <template #append>
              <el-button :icon="Search" @click="onSearch" />
            </template>
          </el-input>
        </el-col>
        <el-col :span="12" class="pr20">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddInvoice"
          >
            {{ $t('invoice') }}
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-table
        ref="tableRef"
        class="fnbinvoicestable-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :data="base.data.records"
        :stripe="true"
        :scrollbar-always-on="true"
        style="width: 100%"
      >
        <el-table-column prop="invoiceNumber" :label="$t('invoiceNumber')" />
        <el-table-column prop="tableNumber" :label="$t('fnbInvoices.tableNumber')" width="200" />
        <el-table-column prop="guestDetails.name" :label="$t('reservations.guestName')" />
        <el-table-column prop="totalPrice" :label="$t('totalPrice')" width="150" />
        <el-table-column label="" width="100">
          <template #default="scope">
            <el-button type="primary" :icon="EditPen" circle @click="handleEditClick(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column label="" width="200">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleGenerateInvoice(scope.row)">
              {{ $t('reservations.generateInvoice') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-pagination
        :current-page="base.data.cursor.currentPage"
        size="small"
        class="m-t-10 mb2 ml10"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="base.data.cursor.perPage"
        :page-sizes="base.pageSizes"
        :total="parseInt(base.data.cursor.totalRecords)"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-model="base.invoiceFormVisible"
      :title="$t('fnbInvoices.title')"
      class="categories-dialog"
      destroy-on-close
      align-center
      fullscreen
    >
      <el-row class="categories-form">
        <el-col class="categories" :span="4">
          <div
            :class="base.selectedCategory === 'allItems' ? 'category-item active' : 'category-item'"
            @click="handleCategoryChange('allItems')"
          >
            All Items
          </div>
          <div
            v-for="cateogory in base.categories"
            :key="cateogory._id"
            :class="
              base.selectedCategory === cateogory.displayName
                ? 'category-item active'
                : 'category-item'
            "
            @click="handleCategoryChange(cateogory.displayName)"
          >
            {{ cateogory.displayName }}
          </div>
        </el-col>
        <el-col :span="14">
          <div class="main-content">
            <div class="products-grid">
              <div
                v-for="item in base.selectedMenuItems"
                :key="item._id"
                class="product-card"
                @click="handleAddMenuItemClick(item)"
              >
                <div class="product-name">{{ item.name }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col class="cart" :span="6">
          <div class="cart-header">
            <div class="cart-actions">
              <h3>Cart</h3>
              <!-- <div>
                <el-button type="plain">Clear</el-button>
              </div> -->
            </div>
            <div class="cart-customer">
              <el-row v-if="base.invoiceForm.guestDetails.name">
                <el-col :span="21">
                  <div class="customer-name">
                    {{ base.invoiceForm.guestDetails.title }}
                    {{ base.invoiceForm.guestDetails.name }}
                  </div>
                </el-col>
                <el-col :span="3" class="fr">
                  <el-button link type="primary" @click="base.guestDetailsDialog = true">
                    {{ $t('edit') }}
                  </el-button>
                </el-col>
              </el-row>
              <div v-else>
                <el-button link type="primary" @click="base.guestDetailsDialog = true">
                  {{ $t('fnbInvoices.guestDetailsTitle') }}
                </el-button>
              </div>
              <div v-if="base.invoiceForm.guestDetails.roomNumber">
                Room No: {{ base.invoiceForm.guestDetails.roomNumber }}
              </div>
              <div v-if="base.invoiceForm.tableNumber">
                Table No: {{ base.invoiceForm.tableNumber }}
              </div>
            </div>
          </div>

          <el-dialog
            v-model="base.guestDetailsDialog"
            width="50%"
            :title="$t('fnbInvoices.guestDetailsTitle')"
            append-to-body
          >
            <el-form label-position="top">
              <el-row class="mt-3" :gutter="20">
                <el-col :span="8" class="tl">
                  <el-form-item :label="$t('reservations.roomNumber')">
                    <el-select
                      v-model="base.invoiceForm.guestDetails.roomInvoiceId"
                      clearable
                      filterable
                      :placeholder="$t('reservations.selectRoom')"
                      @change="handleReservationChange"
                    >
                      <el-option
                        v-for="item in base.reservations"
                        :key="item._id"
                        value-key="_id"
                        :label="`${item.room.roomNumber} - ${item.guestDetails.name}`"
                        :value="item._id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('title')" prop="guestDetails.title">
                    <el-select
                      v-model="base.invoiceForm.guestDetails.title"
                      :disabled="!!base.invoiceForm.guestDetails.roomInvoiceId"
                      :placeholder="$t('common.select')"
                    >
                      <el-option
                        v-for="item in base.titleOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('reservations.guestName')" prop="guestDetails.name">
                    <el-input
                      v-model="base.invoiceForm.guestDetails.name"
                      :disabled="!!base.invoiceForm.guestDetails.roomInvoiceId"
                      autocomplete="off"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('fnbInvoices.tableNumber')" prop="tableNumber">
                    <el-input v-model="base.invoiceForm.tableNumber" autocomplete="off" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-dialog>

          <div v-if="base.invoiceForm.items.length > 0" class="cart-items">
            <div v-for="item in base.invoiceForm.items" :key="item.id" class="cart-item">
              <div class="cart-item-header">
                <div class="customer-name">{{ item.name }}</div>
                <div>₹ {{ item.price }}</div>
              </div>
              <div class="cart-item-controls">
                <div class="quantity-controls">
                  <el-button
                    class="fr quantity-icon"
                    :icon="Minus"
                    @click="handleQuantityChange(item)"
                  ></el-button>
                  <span class="quantity-display">{{ item.quantity }}</span>
                  <el-button
                    class="fr quantity-icon"
                    type="primary"
                    :icon="Plus"
                    @click="handleQuantityChange(item, 'add')"
                  ></el-button>
                </div>
                <el-button
                  class="fr quantity-icon"
                  type="danger"
                  :icon="Delete"
                  @click="handleDeleteItem(item)"
                ></el-button>
              </div>
            </div>
          </div>
          <div v-if="base.invoiceForm.items.length > 0" class="cart-footer">
            <div class="total">
              <span>Total</span>
              <span>₹ {{ base.invoiceForm.totalPrice }}</span>
            </div>
            <el-row class="payment-buttons">
              <el-col :span="6">
                <el-button
                  :type="base.invoiceForm.paymentMode === 'Cash' ? 'primary' : 'plain'"
                  class="w-100"
                  @click="base.invoiceForm.paymentMode = 'Cash'"
                >
                  Cash
                </el-button>
              </el-col>
              <el-col :span="10">
                <el-button
                  :type="base.invoiceForm.paymentMode === 'Credit Card' ? 'primary' : 'plain'"
                  class="w-100"
                  @click="base.invoiceForm.paymentMode = 'Credit Card'"
                >
                  Credit Card
                </el-button>
              </el-col>
              <el-col :span="8">
                <el-button
                  :type="base.invoiceForm.paymentMode === 'UPI' ? 'primary' : 'plain'"
                  class="w-100"
                  @click="base.invoiceForm.paymentMode = 'UPI'"
                >
                  UPI
                </el-button>
              </el-col>
              <el-col>
                <el-button type="primary" class="w-100 mt10" @click="handleSave">
                  {{ $t('reservations.save') }}
                </el-button>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Search, Plus, Minus, EditPen, Delete } from '@element-plus/icons-vue'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { ElNotification, ElMessage } from 'element-plus'
import moment from 'moment'

import constants from '@/constants'
import FNBInvoiceSvc from '@/services/fnbInvoice'
import MenuItemSvc from '@/services/menuItem'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import InvoiceSvc from '@/services/invoice'
import CategorySvc from '@/services/category'
import { useI18n } from 'vue-i18n'

export default {
  name: 'FNBInvoicesTable',
  setup() {
    const { t } = useI18n()
    const menuItemRef = ref(null)
    const base = reactive({
      loading: false,
      invoiceFormVisible: false,
      menuItemDialogVisible: false,
      search: {
        text: '',
        key: 'invoiceNumber'
      },
      menuItemForm: {},
      invoiceForm: {},
      data: {
        records: [],
        cursor: { currentPage: 0, perPage: 0, totalRecords: 0 }
      },
      pageSizes: constants.DEFAULT_PAGE_SIZES,
      menuItems: [],
      selectedMenuItems: [],
      menuItemRules: {
        category: [{ required: true, message: ' ', trigger: 'blur' }],
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        quantity: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      reservations: [],
      titleOptions: constants.TITLE_OPTIONS,
      categories: [],
      selectedCategory: 'allItems'
    })

    const local = {
      currentQuery: undefined,
      sort: {},
      fields: undefined
    }

    const onSearch = () => {
      let q = local.currentQuery
      if (base.search.text) {
        q = {}
        local.currentPage = 1
        // if (searchKeyType === 'date') {
        //   q[searchKey] = {
        //     $gte: QueryBuilder.getDate(searchText, 1),
        //     $lt: QueryBuilder.getDate(searchText, 2)
        //   }
        // } else {
        //   q[searchKey] = { $regex: '.*' + searchText + '.*', $options: 'i' }
        // }
        q[base.search.key] = {
          $regex: '.*' + base.search.text + '.*',
          $options: 'i'
        }
        if (base.search.key === 'invoiceNumber') {
          const value = base.search.text.split('-')
          const searchText = value?.[1] ?? base.search.text
          q[base.search.key] = parseInt(searchText)
        }
      } else {
        q = {}
      }
      if (!_.isEqual(q, local.currentQuery)) {
        local.currentQuery = q
        fetchFNBInvoices(
          local.currentQuery,
          local.fields,
          base.data.cursor.currentPage,
          base.data.cursor.perPage,
          local.sort
        )
      }
    }

    onMounted(async () => {
      await fetchCategories()
      await fetchMenuItems()
      fetchInvoices()
      fetchFNBInvoices()
    })

    const fetchCategories = () => {
      const options = AuthSvc.getOptions()
      CategorySvc.find(options, {}, undefined, 1, 1000)
        .then(response => {
          base.categories = response.data.records
        })
        .catch(error => {
          base.loading = false
          console.log('Categories find error => ', error)
        })
    }

    const fetchInvoices = () => {
      const options = AuthSvc.getOptions()
      const fromDate = moment().startOf('day')._d
      // const toDate = moment().endOf('day')._d
      const query = {
        checkIn: {
          $lte: fromDate
        },
        checkOut: {
          $gt: fromDate
        },
        isCheckedOut: false
      }
      InvoiceSvc.find(options, query, undefined, 1, 1000)
        .then(response => {
          base.reservations = response.data.records
        })
        .catch(error => {
          console.log('FNBInvoicesTable fetchInvoices error => ', error)
        })
    }

    const handleReservationChange = val => {
      const reservation = base.reservations.find(r => r._id === val)
      if (reservation) {
        base.invoiceForm.guestDetails.roomNumber = reservation.room.roomNumber
        base.invoiceForm.guestDetails.name = reservation.guestDetails.name
        base.invoiceForm.guestDetails.title = reservation.guestDetails.title
      } else {
        base.invoiceForm.guestDetails = {}
      }
    }

    const fetchFNBInvoices = (query, projection, page, limit, sort) => {
      const options = AuthSvc.getOptions()
      base.loading = true
      FNBInvoiceSvc.find(options, query, projection, page, limit, sort)
        .then(response => {
          base.data = response.data
          base.loading = false
        })
        .catch(error => {
          base.loading = false
          console.log('FNBInvoicesTable fetch error => ', error)
          common.showErrors(error)
        })
    }

    const handleSave = () => {
      const options = AuthSvc.getOptions()
      if (_.isEmpty(base.invoiceForm.tableNumber)) {
        ElNotification({
          title: 'Error',
          message: 'Kindly enter the table number before proceeding.',
          type: 'error'
        })
      } else if (
        _.isEmpty(base.invoiceForm.guestDetails.title) ||
        _.isEmpty(base.invoiceForm.guestDetails.name)
      ) {
        ElNotification({
          title: 'Error',
          message: 'Kindly enter the guests name and title before proceeding.',
          type: 'error'
        })
      } else if (base.invoiceForm?.items?.length === 0) {
        ElNotification({
          title: 'Error',
          message: 'Please add atleast one menu item',
          type: 'error'
        })
      } else {
        base.loading = true
        FNBInvoiceSvc.save(options, base.invoiceForm)
          .then(() => {
            base.invoiceFormVisible = false
            ElMessage({
              type: 'success',
              message: 'Invoice saved successfully'
            })
            fetchFNBInvoices()
          })
          .catch(error => {
            base.loading = false
            console.log('FNBInvoicesTable handleSave error => ', error)
            common.showErrors(error)
          })
      }
    }

    const fetchMenuItems = async () => {
      const options = AuthSvc.getOptions()
      await MenuItemSvc.find(options, undefined, undefined, 1, 1000)
        .then(response => {
          base.menuItems = response.data.records
          base.selectedMenuItems = _.clone(base.menuItems)
        })
        .catch(error => {
          console.log('FNBInvoicesTable fetchMenuItems error => ', error)
          common.showErrors(error)
        })
    }

    const handleCreateNewMenuItem = item => {
      return {
        id: uuidv4(),
        category: item._id,
        name: item.name,
        quantity: 1,
        price: item.price.default,
        itemPrice: _.clone(item.price.default),
        taxPercentage: item.taxPercentage
      }
    }

    const handleCreateNewInvoice = () => {
      return {
        guestDetails: {
          roomInvoiceId: '',
          roomNumber: ''
        },
        items: [],
        totalPrice: 0,
        paymentMode: 'Cash',
        tableNumber: ''
      }
    }

    const handleAddInvoice = () => {
      base.invoiceForm = handleCreateNewInvoice()
      base.invoiceFormVisible = true
    }

    const handleEditClick = fnbInvoice => {
      const options = AuthSvc.getOptions()
      FNBInvoiceSvc.findById(options, fnbInvoice._id)
        .then(response => {
          const invoice = response.data
          if (!invoice.guestDetails) {
            invoice.guestDetails = {}
          }
          base.invoiceForm = invoice
          base.invoiceFormVisible = true
        })
        .catch(error => {
          console.log('FNBInvoicesTable handleEditClick error => ', error)
          common.showErrors(error)
        })
    }

    const handleCategoryChange = category => {
      base.selectedCategory = category
      if (category !== 'allItems') {
        base.selectedMenuItems = base.menuItems.filter(item => item.category === category)
      } else {
        base.selectedMenuItems = _.clone(base.menuItems)
      }
    }

    const handleQuantityChange = (item, operation) => {
      const menuItem = base.invoiceForm.items.find(i => i.id === item.id)
      if (menuItem) {
        if (operation === 'add') {
          menuItem.quantity = menuItem.quantity + 1
        } else {
          menuItem.quantity = menuItem.quantity > 1 ? menuItem.quantity - 1 : menuItem.quantity
        }
        menuItem.price = menuItem.itemPrice * menuItem.quantity
        calculateTotal()
      }
    }

    const handleDeleteItem = item => {
      const index = base.invoiceForm.items.findIndex(i => i.id === item.id)
      if (index !== -1) {
        base.invoiceForm.items.splice(index, 1)
      }
      calculateTotal()
    }

    const calculateTotal = () => {
      base.invoiceForm.totalPrice = 0
      base.invoiceForm.items.forEach(i => (base.invoiceForm.totalPrice += i.price))
      base.invoiceForm.totalPrice.toFixed(2)
    }

    const handleAddMenuItemClick = item => {
      const menuItemForm = handleCreateNewMenuItem(item)
      base.invoiceForm.items.push(menuItemForm)
      calculateTotal()
    }

    const handleCurrentPageChange = val => {
      base.data.cursor.currentPage = val
      fetchFNBInvoices(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    const handlePageSizeChange = val => {
      base.data.cursor.perPage = val
      fetchFNBInvoices(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    const handleGenerateInvoice = row => {
      base.loading = true
      const options = AuthSvc.getOptions()
      FNBInvoiceSvc.generate(options, row._id)
        .then(res => {
          base.loading = false
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob) // Create a temporary URL for the file
          const newWindow = window.open(url, '_blank') // Open the file in a new tab or window

          if (newWindow) {
            newWindow.onload = () => {
              newWindow.print() // Trigger the print dialog once the file is loaded
            }
          }

          // Revoke the Object URL to release memory
          window.URL.revokeObjectURL(url)
          ElNotification({
            type: 'success',
            message: 'Invoice generated successfully!'
          })
        })
        .catch(error => {
          base.loading = false
          console.log('DNBInvoicesTable handleGenerateInvoice error => ', error)
          common.showErrors(error)
        })
    }

    return {
      t,
      base,
      menuItemRef,
      Search,
      Plus,
      Minus,
      EditPen,
      Delete,
      handleEditClick,
      handleAddInvoice,
      handleSave,
      handleCategoryChange,
      handleQuantityChange,
      handleAddMenuItemClick,
      handleDeleteItem,
      handleCurrentPageChange,
      handlePageSizeChange,
      onSearch,
      handleGenerateInvoice,
      handleReservationChange
    }
  }
}
</script>

<style>
.fnbinvoicestable-max-height {
  height: calc(100vh - 205px) !important;
}
.items-table-max-height {
  min-height: calc(100vh - 286px);
  max-height: calc(100vh - 286px);
}
.justify-content-right {
  justify-content: flex-end;
  display: flex !important;
}
.categories {
  background-color: #f3f4f6;
  overflow-y: auto;
  padding: 1rem;
}
.category-item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
  transition: background-color 0.2s;
}
.category-item:hover {
  background-color: #e5e7eb;
}
.category-item.active {
  background-color: #dbeafe;
  color: #2563eb;
}
.categories-form {
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow: auto;
}
.categories-dialog {
  overflow: hidden !important;
}
.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.products-grid {
  padding: 1rem;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}
.product-card {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.2s;
  padding: 1.5rem;
  background-color: #f3f4f6;
  text-align: center;
}
.product-card:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  background-color: #e5e7eb;
}
.product-name {
  font-weight: 500;
  font-size: 1.1rem;
}
.cart {
  border-left: 1px solid #e5e7eb;
}
.cart-header {
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
}
.cart-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.cart-customer {
  padding: 1rem;
  background-color: #f3f4f6;
  border-radius: 0.375rem;
}
.customer-name {
  color: #2563eb;
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.cart-items {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
}
.cart-item {
  background-color: #f8fafc;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}
.cart-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.cart-item-details {
  color: #64748b;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}
.cart-item-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  border-top: 1px solid #e5e7eb;
}
.quantity-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.quantity-btn {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 4px;
  background-color: #e5e7eb;
  color: #4b5563;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.quantity-btn:hover {
  background-color: #d1d5db;
}
.quantity-display {
  width: 40px;
  text-align: center;
  font-weight: 500;
}
.delete-btn {
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #fee2e2;
  color: #dc2626;
  cursor: pointer;
  font-size: 0.875rem;
}
.delete-btn:hover {
  background-color: #fecaca;
}
.cart-footer {
  padding: 1rem;
  border-top: 1px solid #e5e7eb;
  background-color: #f8fafc;
}
.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
}
.payment-buttons .el-button + .el-button {
  margin-left: 0px;
}
.payment-buttons .el-button {
  padding: 10px;
}
.quantity-icon {
  padding: 10px;
  font-weight: 800;
}
</style>
