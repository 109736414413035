<template>
  <div v-loading.fullscreen.lock="base.loading" class="card menu-items">
    <el-row>
      <el-col :span="3" class="add-category">
        <el-button
          class="menu-items-category-button text-uppercase"
          type="danger"
          size="small"
          :icon="Plus"
          @click="handleAddCategory"
        >
          Category
        </el-button>
      </el-col>
    </el-row>
    <el-tabs
      v-model="base.activeTab"
      class="menu-items-tab"
      closable
      tab-position="left"
      @tab-remove="handleRemoveCategory"
    >
      <!-- <template #add-icon>
        <el-button
          class="menu-items-category-button text-uppercase"
          type="danger"
          size="small"
          :icon="Plus"
          @click="handleAddCategory"
        >
          Category
        </el-button>
      </template> -->
      <el-tab-pane
        v-for="item in base.categories"
        :key="item._id"
        :label="item.displayName"
        :name="item.displayName"
        class="category-tab"
      >
        <menu-item-tab-content
          v-if="base.activeTab === item.displayName"
          :category="item.displayName"
        ></menu-item-tab-content>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      v-model="base.categoryFormVisible"
      title="Add Category"
      width="50%"
      destroy-on-close
      align-center
    >
      <el-form
        ref="categoryFromRef"
        class="mt20"
        :model="base.categoryForm"
        :rules="base.categoryRules"
      >
        <el-col>
          <el-form-item :label="$t('displayName')" prop="displayName">
            <el-input v-model="base.categoryForm.displayName" size="large" autocomplete="off" />
          </el-form-item>
        </el-col>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.categoryFormVisible = false">
            {{ $t('reservations.cancel') }}
          </el-button>
          <el-button type="primary" @click="handleSave">Confirm</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import MenuItemSvc from '@/services/menuItem'
import AuthSvc from '@/services/auth'
import CategorySvc from '@/services/category'
import common from '@/utils/common'
// import _ from 'lodash'
import { useI18n } from 'vue-i18n'
import MenuItemTabContent from './MenuItemTabContent.vue'
// import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'MenuItemsTable',
  components: {
    MenuItemTabContent
  },
  setup() {
    const categoryFromRef = ref(null)
    const { t } = useI18n()
    const base = reactive({
      loading: false,
      categories: [],
      categoryFormVisible: false,
      categoryForm: {
        displayName: ''
      },
      categoryRules: [],
      activeTab: ''
    })

    onMounted(async () => {
      await fetchCategories()
      fetchMenuItems()
    })

    const fetchCategories = (loading = true) => {
      const options = AuthSvc.getOptions()
      CategorySvc.find(options, {}, undefined, 1, 1000)
        .then(response => {
          base.categories = response.data.records
          base.activeTab = base.categories[0]?.displayName || ''
          if (!loading) {
            base.loading = false
          }
        })
        .catch(error => {
          base.loading = false
          console.log('Categories find error => ', error)
        })
    }

    const handleRemoveCategory = row => {
      ElMessageBox.confirm('Are you sure you wanted to delete the category?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        const options = AuthSvc.getOptions()
        const _id = base.categories.find(c => c.displayName === row)?._id
        CategorySvc.remove(options, _id)
          .then(() => {
            fetchCategories(false)
            ElMessage({
              type: 'success',
              message: 'Category deleted successfully.'
            })
            base.categoryFormVisible = false
          })
          .catch(error => {
            base.loading = false
            console.log('Error saving categories', error)
            common.showErrors(error)
          })
      })
      console.log('row', row)
    }

    const fetchMenuItems = () => {
      base.loading = true
      const options = AuthSvc.getOptions()
      const query = {}
      MenuItemSvc.find(options, query, 'category', 1, 10000)
        .then(() => {
          // const records = response.data.records
          // base.categories = _.uniqBy(records, 'category')
          // base.activeTab = base.categories[0]?.category || ''
          base.loading = false
        })
        .catch(error => {
          base.loading = false
          console.log('MenuItemsTable fetchMenuItems error => ', error)
          common.showErrors(error)
        })
    }

    const handleSave = () => {
      categoryFromRef.value.validate(valid => {
        if (valid) {
          base.loading = true
          const options = AuthSvc.getOptions()
          CategorySvc.save(options, base.categoryForm)
            .then(() => {
              fetchCategories(false)
              ElMessage({
                type: 'success',
                message: 'Category saved successfully.'
              })
              base.categoryFormVisible = false
            })
            .catch(error => {
              base.loading = false
              console.log('Error saving categories', error)
            })
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const handleAddCategory = () => {
      base.categoryForm.displayName = ''
      base.categoryFormVisible = true
    }

    return {
      t,
      base,
      Plus,
      handleAddCategory,
      handleSave,
      handleRemoveCategory,
      categoryFromRef
    }
  }
}
</script>

<style>
.menu-items {
  padding: 10px;
  min-height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
}
.menu-items-tab {
  height: 100%;
}
.menu-items-category-button {
  padding: 12px !important;
}
.add-category {
  margin-top: 5px;
  margin-left: 15px;
}
</style>
