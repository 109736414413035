<template>
  <div v-loading.fullscreen.lock="base.loading">
    <el-row class="fr mb10">
      <el-button
        class="menu-items-category-button text-uppercase"
        type="primary"
        size="small"
        :icon="Plus"
        @click="handleAddMenuItem"
      >
        Menu Item
      </el-button>
    </el-row>
    <el-table
      ref="tableRef"
      border
      class="menuitemstable-max-height"
      row-key="email"
      header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
      row-class-name="text-secondary text-xs font-weight-bold"
      :data="base.data.records"
      :stripe="true"
      :scrollbar-always-on="true"
      style="width: 100%"
    >
      <el-table-column prop="name" :label="$t('name')" />
      <el-table-column prop="price.default" :label="$t('price')" />
      <el-table-column prop="taxPercentage" :label="$t('roomTypes.taxPercentage')" />
      <el-table-column prop="isActive" :label="$t('isActive')" width="100">
        <template #default="scope">
          <span v-if="scope.row.isActive" class="badge bg-gradient-success">
            {{ $t('yes') }}
          </span>
          <span v-else class="badge bg-gradient-secondary">{{ $t('no') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="" width="150">
        <template #default="scope">
          <el-button type="primary" :icon="EditPen" circle @click="handleEditClick(scope.row)" />
          <el-button type="danger" :icon="Delete" circle @click="handleDeleteClick(scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-pagination
        :current-page="base.data.cursor.currentPage"
        size="small"
        class="m-t-10 mb2 ml10"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="base.data.cursor.perPage"
        :page-sizes="base.pageSizes"
        :total="parseInt(base.data.cursor.totalRecords)"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
      <el-dialog
        v-model="base.menuItemFormVisible"
        title="Menu Item Information"
        width="70%"
        destroy-on-close
        align-center
      >
        <el-form
          ref="menuItemRef"
          :model="base.menuItemForm"
          label-position="top"
          :rules="base.menuItemRules"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item :label="$t('fnbInvoices.category')" prop="category">
                <el-input
                  v-model="base.menuItemForm.category"
                  disabled
                  size="large"
                  autocomplete="off"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('name')" prop="name">
                <el-input v-model="base.menuItemForm.name" size="large" autocomplete="off" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('price')" prop="price.default">
                <el-input-number
                  v-model="base.menuItemForm.price.default"
                  class="w-100"
                  :controls="false"
                  size="large"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('roomTypes.taxPercentage')">
                <el-input-number
                  v-model="base.menuItemForm.taxPercentage"
                  class="w-100"
                  :controls="false"
                  size="large"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('isActive')">
                <el-switch
                  v-model="base.menuItemForm.isActive"
                  size="large"
                  active-text="Yes"
                  inactive-text="No"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="base.menuItemFormVisible = false">
              {{ $t('reservations.cancel') }}
            </el-button>
            <el-button type="primary" @click="handleSave">Confirm</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, reactive, onMounted } from 'vue'
import { Search, Plus, Delete, EditPen } from '@element-plus/icons-vue'
import MenuItemSvc from '@/services/menuItem'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import { ElNotification } from 'element-plus'
import constants from '@/constants'
import _ from 'lodash'
import { useI18n } from 'vue-i18n'

export default {
  name: 'MenuItemTabContent',
  props: {
    category: {
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n()
    const menuItemRef = ref(null)
    const base = reactive({
      menuItemFormVisible: false,
      search: {
        text: '',
        key: 'name'
      },
      menuItemForm: {
        category: '',
        name: '',
        price: {
          default: 0
        },
        taxPercentage: 5,
        isActive: true
      },
      data: {
        records: [],
        cursor: { currentPage: 0, perPage: 0, totalRecords: 0 }
      },
      pageSizes: constants.DEFAULT_PAGE_SIZES,
      menuItemRules: {
        category: [{ required: true, message: ' ', trigger: 'blur' }],
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        'price.default': [{ required: true, message: ' ', trigger: 'blur' }]
      },
      currentQuery: {},
      sort: {},
      fields: undefined,
      loading: false
    })

    const onSearch = () => {
      let q = base.currentQuery
      if (base.search.text) {
        q = {}
        base.currentPage = 1
        // if (searchKeyType === 'date') {
        //   q[searchKey] = {
        //     $gte: QueryBuilder.getDate(searchText, 1),
        //     $lt: QueryBuilder.getDate(searchText, 2)
        //   }
        // } else {
        //   q[searchKey] = { $regex: '.*' + searchText + '.*', $options: 'i' }
        // }
        q[base.search.key] = {
          $regex: '.*' + base.search.text + '.*',
          $options: 'i'
        }
      } else {
        q = {}
      }
      if (!_.isEqual(q, base.currentQuery)) {
        base.currentQuery = q
        fetchMenuItems(
          base.currentQuery,
          base.fields,
          base.data.cursor.currentPage,
          base.data.cursor.perPage,
          base.sort
        )
      }
    }

    onMounted(() => {
      fetchMenuItems(
        base.currentQuery,
        base.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        base.sort
      )
    })

    const fetchMenuItems = (query, projection, page, limit, sort) => {
      base.loading = true
      const options = AuthSvc.getOptions()
      query.category = props.category
      MenuItemSvc.find(options, query, projection, page, limit, sort)
        .then(response => {
          base.data = response.data
          base.loading = false
        })
        .catch(error => {
          base.loading = false
          console.log('MenuItemsTable fetchMenuItems error => ', error)
          common.showErrors(error)
        })
    }

    const createNewMenuItem = () => {
      return {
        category: props.category,
        name: '',
        price: {
          default: 0
        },
        taxPercentage: 5,
        isActive: true
      }
    }

    const handleAddMenuItem = () => {
      base.menuItemForm = createNewMenuItem()
      base.menuItemFormVisible = true
    }

    const handleEditClick = menuItem => {
      const options = AuthSvc.getOptions()
      MenuItemSvc.findById(options, menuItem._id)
        .then(response => {
          base.menuItemForm = response.data
          base.menuItemFormVisible = true
        })
        .catch(error => {
          console.log('MenuItemsTable handleEditClick error => ', error)
          common.showErrors(error)
        })
    }

    const handleDeleteClick = menuItem => {
      ElMessageBox.confirm('Are you sure you wanted to delete the menu item?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        base.loading = true
        const options = AuthSvc.getOptions()
        MenuItemSvc.remove(options, menuItem._id)
          .then(() => {
            fetchMenuItems(base.currentQuery, base.fields, 1, base.data.cursor.perPage, base.sort)
            ElMessage({
              type: 'success',
              message: 'Menu item deleted successfully'
            })
          })
          .catch(error => {
            base.loading = false
            console.log('MenuItemsTable handleEditClick error => ', error)
            common.showErrors(error)
          })
      })
    }

    const handleSave = () => {
      menuItemRef.value.validate(valid => {
        if (valid) {
          const options = AuthSvc.getOptions()
          MenuItemSvc.save(options, base.menuItemForm)
            .then(result => {
              const message =
                result.status === 201
                  ? 'Menu Item created successfully'
                  : 'Menu Item updated successfully'
              ElNotification({
                message,
                type: 'success'
              })
              base.menuItemFormVisible = false
              fetchMenuItems(
                base.currentQuery,
                base.fields,
                base.data.cursor.currentPage,
                base.data.cursor.perPage,
                base.sort
              )
            })
            .catch(error => {
              console.log('MenuItemsTable handleSave error => ', error)
              common.showErrors(error)
            })
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const handleCurrentPageChange = val => {
      base.data.cursor.currentPage = val
      fetchMenuItems(
        base.currentQuery,
        base.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        base.sort
      )
    }

    const handlePageSizeChange = val => {
      base.data.cursor.perPage = val
      fetchMenuItems(
        base.currentQuery,
        base.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        base.sort
      )
    }

    return {
      t,
      base,
      menuItemRef,
      Search,
      Plus,
      Delete,
      EditPen,
      handleEditClick,
      handleDeleteClick,
      handleAddMenuItem,
      handleSave,
      handleCurrentPageChange,
      handlePageSizeChange,
      onSearch
    }
  }
}
</script>

<style>
.menuitemstable-max-height {
  height: calc(100vh - 220px) !important;
}
.menu-items {
  padding: 10px;
}
.menu-items-tab {
  height: 100%;
}
.menu-items-category-button {
  padding: 12px !important;
}
</style>
