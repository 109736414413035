<template>
  <div v-loading.fullscreen.lock="base.loading" class="card">
    <div class="card-header px-2 pb-0">
      <el-row>
        <el-col :span="12">
          <el-input
            v-model="base.search.text"
            :placeholder="$t('common.inputPlaceholder')"
            clearable
            class="input-with-select"
            @keyup.enter="onSearch"
          >
            <template #prepend>
              <el-select
                v-model="base.search.key"
                :placeholder="$t('common.select')"
                style="width: 150px"
              >
                <el-option :label="$t('reservations.roomNumber')" value="roomNumber" />
                <el-option :label="$t('roomsTable.floor')" value="floor" />
                <el-option :label="$t('roomsTable.roomType')" value="roomType.name" />
              </el-select>
            </template>
            <template #append>
              <el-button :icon="Search" @click="onSearch" />
            </template>
          </el-input>
        </el-col>
        <el-col :span="12" class="pr20">
          <el-button
            class="text-uppercase ms-auto fr"
            type="primary"
            :icon="Plus"
            @click="handleAddRoom"
          >
            {{ $t('roomsTable.addRoom') }}
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-table
        ref="tableRef"
        class="rooms-table-max-height"
        row-key="email"
        header-cell-class-name="text-uppercase text-secondary text-xs font-weight-bolder opacity-7"
        row-class-name="text-secondary text-xs font-weight-bold"
        :stripe="true"
        :scrollbar-always-on="true"
        :data="base.data.records"
        style="width: 100%"
      >
        <el-table-column prop="roomNumber" :label="$t('reservations.roomNumber')" />
        <el-table-column prop="floor" :label="$t('roomsTable.floor')" />
        <el-table-column prop="roomType.name" :label="$t('roomsTable.roomType')" />
        <el-table-column prop="isActive" :label="$t('isActive')">
          <template #default="scope">
            <span v-if="scope.row.isActive" class="badge bg-gradient-success">{{ $t('yes') }}</span>
            <span v-else class="badge bg-gradient-secondary">{{ $t('no') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="" min-width="75">
          <template #default="scope">
            <el-button type="primary" :icon="EditPen" circle @click="handleEditClick(scope.row)" />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="card-body px-0 pt-0 pb-2 mt-2">
      <el-pagination
        :current-page="base.data.cursor.currentPage"
        size="small"
        class="m-t-10 mb2 ml10"
        layout="total, sizes, prev, pager, next, jumper"
        :page-size="base.data.cursor.perPage"
        :page-sizes="base.pageSizes"
        :total="parseInt(base.data.cursor.totalRecords)"
        @size-change="handlePageSizeChange"
        @current-change="handleCurrentPageChange"
      ></el-pagination>
    </div>
    <el-dialog
      v-model="base.roomFormVisible"
      :title="$t('roomsTable.dialogTitle')"
      width="70%"
      destroy-on-close
      align-center
    >
      <el-form ref="roomRef" :model="base.roomForm" label-position="top" :rules="base.roomRules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item :label="$t('roomsTable.roomType')" prop="roomType">
              <el-select
                v-model="base.roomForm.roomType"
                :placeholder="$t('common.select')"
                size="large"
                value-key="name"
              >
                <el-option
                  v-for="item in base.roomTypes"
                  :key="item.name"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('reservations.roomNumber')" prop="roomNumber">
              <el-input v-model="base.roomForm.roomNumber" size="large" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('roomsTable.floor')" prop="floor">
              <el-input v-model="base.roomForm.floor" size="large" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('isActive')">
              <el-switch
                v-model="base.roomForm.isActive"
                size="large"
                active-text="Yes"
                inactive-text="No"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="base.roomFormVisible = false">
            {{ $t('reservations.cancel') }}
          </el-button>
          <el-button type="primary" @click="handleSave">{{ $t('confirm') }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Search, Plus, EditPen } from '@element-plus/icons-vue'
import RoomSvc from '@/services/room'
import RoomTypeSvc from '@/services/roomType'
import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import _ from 'lodash'
import { ElNotification } from 'element-plus'
import constants from '@/constants'
import { useI18n } from 'vue-i18n'

export default {
  name: 'RoomsTable',
  setup() {
    const { t } = useI18n()
    const handleValidation = (rule, value, callback) => {
      _.isEmpty(value) ? callback(new Error(' ')) : callback()
    }
    const roomRef = ref(null)
    const base = reactive({
      loading: false,
      roomFormVisible: false,
      search: {
        text: '',
        key: 'roomNumber'
      },
      roomForm: {
        roomNumber: '',
        floor: '',
        roomType: {},
        isActive: true
      },
      data: {
        records: [],
        cursor: { currentPage: 0, perPage: 0, totalRecords: 0 }
      },
      pageSizes: constants.DEFAULT_PAGE_SIZES,
      roomTypes: [],
      roomRules: {
        roomNumber: [{ required: true, message: ' ', trigger: 'blur' }],
        floor: [{ required: true, message: ' ', trigger: 'blur' }],
        roomType: [
          {
            required: true,
            validator: handleValidation,
            message: '',
            trigger: 'blur'
          }
        ]
      }
    })

    const local = {
      currentQuery: undefined,
      sort: {},
      fields: undefined
    }

    const onSearch = () => {
      let q = local.currentQuery
      if (base.search.text) {
        q = {}
        local.currentPage = 1
        // if (searchKeyType === 'date') {
        //   q[searchKey] = {
        //     $gte: QueryBuilder.getDate(searchText, 1),
        //     $lt: QueryBuilder.getDate(searchText, 2)
        //   }
        // } else {
        //   q[searchKey] = { $regex: '.*' + searchText + '.*', $options: 'i' }
        // }
        q[base.search.key] = {
          $regex: '.*' + base.search.text + '.*',
          $options: 'i'
        }
      } else {
        q = {}
      }
      if (!_.isEqual(q, local.currentQuery)) {
        local.currentQuery = q
        fetchRooms(
          local.currentQuery,
          local.fields,
          base.data.cursor.currentPage,
          base.data.cursor.perPage,
          local.sort
        )
      }
    }

    onMounted(async () => {
      base.loading = true
      await fetchRooms()
      fetchRoomTypes()
    })

    const fetchRooms = async (query, projection, page, limit, sort) => {
      base.loading = true
      const options = AuthSvc.getOptions()
      await RoomSvc.find(options, query, projection, page, limit, sort)
        .then(response => {
          base.data = response.data
          base.loading = false
        })
        .catch(error => {
          base.loading = false
          console.log('RoomsTable fetchRooms error => ', error)
          common.showErrors(error)
        })
    }

    const handleSave = () => {
      roomRef.value.validate(valid => {
        if (valid) {
          base.loading = true
          const options = AuthSvc.getOptions()
          RoomSvc.save(options, base.roomForm)
            .then(response => {
              const message =
                response.status === 201 ? 'Room created successfully' : 'Room updated successfully'
              ElNotification({
                message,
                type: 'success'
              })
              base.roomFormVisible = false
              fetchRooms()
            })
            .catch(error => {
              base.loading = false
              console.log('RoomsTable handleSave error => ', error)
              common.showErrors(error)
            })
        } else {
          common.showMandatoryValidation()
        }
      })
    }

    const fetchRoomTypes = () => {
      const options = AuthSvc.getOptions()
      RoomTypeSvc.find(options, undefined, '_id,name', 1, 1000)
        .then(response => {
          base.roomTypes = response.data.records.map(roomType => {
            return {
              name: roomType.name,
              id: roomType._id
            }
          })
        })
        .catch(error => {
          console.log('RoomsTable fetchRoomTypes error => ', error)
          common.showErrors(error)
        })
    }

    const handleCreateNewRoom = () => {
      return {
        roomNumber: '',
        floor: '',
        roomType: {},
        isActive: true
      }
    }

    const handleAddRoom = () => {
      base.roomForm = handleCreateNewRoom()
      base.roomFormVisible = true
    }

    const handleEditClick = room => {
      base.loading = true
      const options = AuthSvc.getOptions()
      RoomSvc.findById(options, room._id)
        .then(response => {
          base.roomForm = response.data
          base.loading = false
          base.roomFormVisible = true
        })
        .catch(error => {
          base.loading = false
          console.log('RoomsTable handleEditClick error => ', error)
          common.showErrors(error)
        })
    }

    const handleCurrentPageChange = val => {
      base.data.cursor.currentPage = val
      fetchRooms(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    const handlePageSizeChange = val => {
      base.data.cursor.perPage = val
      fetchRooms(
        local.currentQuery,
        local.fields,
        base.data.cursor.currentPage,
        base.data.cursor.perPage,
        local.sort
      )
    }

    return {
      t,
      base,
      roomRef,
      Search,
      EditPen,
      Plus,
      handleEditClick,
      handleAddRoom,
      handleSave,
      handleCurrentPageChange,
      handlePageSizeChange,
      onSearch
    }
  }
}
</script>

<style>
.rooms-table-max-height {
  height: calc(100vh - 205px) !important;
}
</style>
