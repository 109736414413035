<template>
  <el-row class="privacy-policy-hero w-100">
    <el-col class="text-center">
      <h2 class="pricing-heading">Privacy Policy</h2>
    </el-col>
    <el-row class="w-100">
      <p>Effective Date: 01/01/2025</p>

      <p>
        We, NIKHSHEP IT SERVCES LLP ("We", "Us", "Our", "Company"), are committed to protecting the
        privacy of our users ("you", "your") who use our application (the website, desktop and
        mobile application known as "ZATASTAYS"). This Privacy Policy outlines how we collect, use,
        disclose, and safeguard your information when you use our platform, services, and website
        ("ZATASTAYS").
      </p>

      <h3>1. Information We Collect</h3>
      <h4>1.1 Personal Information</h4>
      <p>We may collect personal information that can identify you, such as:</p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Payment information (e.g., credit card or bank account details)</li>
        <li>Government-issued ID for verification purposes</li>
      </ul>

      <h4>1.2 Automatically Collected Information</h4>
      <p>We may also collect information automatically, including:</p>
      <ul>
        <li>IP address</li>
        <li>Device information</li>
        <li>Browser type</li>
        <li>Access times</li>
        <li>Pages viewed</li>
        <li>Location data</li>
        <li>Cookies and other tracking technologies</li>
      </ul>

      <h4>1.3 Third-Party Information</h4>
      <p>
        We may collect information from third-party sources, such as social media platforms or
        service providers.
      </p>

      <h3>2. How We Use Your Information</h3>
      <p>We use the information collected for the following purposes:</p>
      <ul>
        <li>To provide, maintain, and improve our Platform and services.</li>
        <li>To process payments and manage your transactions.</li>
        <li>
          To communicate with you regarding your account, transactions, and promotional offers.
        </li>
        <li>To enhance user experience and personalize the services.</li>
        <li>To comply with legal and regulatory requirements.</li>
        <li>To diagnose and resolve technical issues.</li>
        <li>To facilitate and manage room bookings.</li>
        <li>To process transactions and send booking confirmations.</li>
        <li>To provide customer support and respond to inquiries.</li>
        <li>To improve our services and website functionality.</li>
      </ul>

      <h3>3. Information Sharing and Disclosure</h3>
      <h4>3.1 Third-Party Service Providers</h4>
      <p>We may share your information with third-party service providers for:</p>
      <ul>
        <li>Payment processing</li>
        <li>Technical support</li>
        <li>Marketing and analytics</li>
        <li>Hosting services</li>
      </ul>

      <h4>3.2 Legal Compliance</h4>
      <p>We may disclose your information to:</p>
      <ul>
        <li>Comply with legal obligations.</li>
        <li>Protect the rights and safety of our Company, users, or others.</li>
        <li>Prevent fraud or other illegal activities.</li>
      </ul>

      <h4>3.3 Business Transfers</h4>
      <p>
        In case of a merger, sale, or acquisition, your information may be transferred as part of
        the transaction.
      </p>

      <h4>3.4 Your Privacy Rights</h4>
      <p>
        You have the right to access, update, and delete your personal information. You may also opt
        out of receiving marketing communications from us. To exercise these rights, please contact
        us at
        <a href="mailto:contactus@zatastays.com">contactus@zatastays.com</a>
        .
      </p>

      <h3>4. Security Measures</h3>
      <p>
        We implement reasonable security measures to protect your data from unauthorized access,
        alteration, disclosure, or destruction. However, no method of transmission over the internet
        or electronic storage is 100% secure. We cannot guarantee absolute security.
      </p>

      <h3>5. Confidentiality Clause</h3>
      <ul>
        <li>
          <strong>Confidential Information:</strong>
          In the course of providing our services, we may have access to confidential information
          related to our customers and partners, including but not limited to, personal data,
          financial information, and business operations.
        </li>
        <li>
          <strong>Non-Disclosure:</strong>
          We agree to keep all confidential information strictly confidential and will not disclose,
          share, or use it for any purpose other than providing our services. We will not share any
          customer data with third parties unless required by government authorities or applicable
          law.
        </li>
        <li>
          <strong>Security Measures:</strong>
          We will implement appropriate security measures to protect confidential information from
          unauthorized access, use, or disclosure. This includes, but is not limited to, encryption,
          access controls, and regular security audits.
        </li>
        <li>
          <strong>Compliance with Laws:</strong>
          We will comply with all applicable data protection and privacy laws in handling
          confidential information, including the General Data Protection Regulation (GDPR) and any
          other relevant legislation.
        </li>
        <li>
          <strong>Termination:</strong>
          Upon termination of our services, we will securely delete or return all confidential
          information in our possession, except where retention is required by law.
        </li>
        <li>
          <strong>Breach Notification:</strong>
          In the event of a security breach that affects confidential information, we will promptly
          notify the affected parties and take appropriate steps to mitigate the impact and prevent
          future occurrences.
        </li>
      </ul>

      <h3>6. User Controls</h3>
      <h4>6.1 Access and Update</h4>
      <p>
        You may access or update your personal information by contacting us at
        <a href="mailto:contactus@zatastays.com">contactus@zatastays.com</a>
        .
      </p>

      <h4>6.2 Opt-Out Options</h4>
      <p>You may opt out of:</p>
      <ul class="w-100">
        <li>Receiving promotional communications by following the unsubscribe link in emails.</li>
        <li>Cookies by adjusting your browser settings.</li>
      </ul>

      <h3>7. Retention of Information</h3>
      <p>
        We retain your information as long as necessary to provide our services, comply with legal
        obligations, and resolve disputes.
      </p>

      <h3>8. Links to Third-Party Websites</h3>
      <p>
        Our Platform may contain links to third-party websites. We are not responsible for the
        privacy practices of these external sites.
      </p>

      <h3>9. Changes to This Policy</h3>
      <p>
        We reserve the right to update this Privacy Policy at any time. Changes will be posted on
        this page, and the "Effective Date" will be updated.
      </p>

      <h3>10. Contact Us</h3>
      <p>For any questions or concerns about this Privacy Policy, please contact us at:</p>
      <p>
        Email:
        <a href="mailto:contactus@zatastays.com">contactus@zatastays.com</a>
      </p>
      <p>Phone: +91 6281652287, +91 9642636232</p>

      <p>By using our Platform, you agree to the terms of this Privacy Policy.</p>
    </el-row>
  </el-row>
</template>

<script>
// import { CloseBold, Select } from '@element-plus/icons-vue'
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'PrivacyPolicy',
  // components: {
  //   CloseBold,
  //   SelectIcon: Select
  // },
  setup() {
    const { t } = useI18n()
    const base = reactive({})

    return {
      t,
      base
    }
  }
}
</script>

<style>
.plan-card {
  padding: 40px;
}
.pricing-heading {
  margin-bottom: 20px;
}
.price {
  text-align: center;
  margin-bottom: 20px;
}
.plan-name {
  text-align: center;
  margin-bottom: 15px;
}
.plan-btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.plan-feature-icon {
  margin-top: 6px;
  margin-right: 10px;
  /* color: #00a3ff; */
}
.privacy-policy-hero {
  padding: 5rem 0rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.privacy-policy-hero .el-row * {
  display: block;
}
.privacy-policy-hero .h3,
h4 {
  width: 100%;
}
.privacy-policy-hero p {
  width: 100%;
}
</style>
