<template>
  <el-row class="privacy-policy-hero w-100">
    <el-col class="text-center">
      <h2 class="pricing-heading">Cancellation & Refund Policy</h2>
    </el-col>
    <el-row class="w-100">
      <p>Last Updated: January 01, 2025</p>

      <p>
        We, NIKHSHEP IT SERVICES LLP ("We", "Us", "Our", "Company"), is in the business of providing
        a cloud-based point of sales software application under the brand name "ZATASTAYS" for
        hospitality businesses like hotels, restaurants, function halls, hostels etc together with
        optionally integrated services such as mobile or card payments, inventory management, deals
        and discounts, etc. using a programmable API architecture.
      </p>

      <p>
        The Company through such point of sale software application and websites (together referred
        to as "ZATASTAYS") also provides end to end integration of services for restaurant
        management to various restaurants and food outlets. The Company has created and owns the
        ZATASTAYS Platform and the use of the same by way of various subscription model for a
        Subscription Fee.
      </p>

      <p>
        The subscription to the ZATASTAYS Platform and other value-added services ("ZATASTAYS
        Subscription") offered by us is subject to our Terms of Services that is agreed by you (or
        anyone who accesses and uses the ZATASTAYS Platforms shall be referred to as "You", "Your"
        or "User" as the context may require) at the time of subscription.
      </p>

      <h3>1. Fees</h3>
      <p>
        You are required to pay subscription fee as per the ZATASTAYS Subscription plan subscribed
        by you ("Subscription Fee"). The Subscription Fee for ZATASTAYS Subscription as stated in
        various subscription plans is non-refundable, no refunds are eligible for services from our
        platform except as expressly set forth in these cancellation and refund terms (hereinafter
        referred to as "Cancellation & Refund Policy"). Taxes may apply on the subscription fee.
      </p>

      <p>
        Your paid ZATASTAYS Subscription will be activated only upon successful payment of the
        Subscription Fee made through the ZATASTAYS Platform as per the applicable subscription plan
        chosen by you.
      </p>

      <h3>2. Subscription Cancellation & Refund</h3>
      <p>
        You may cancel your paid ZATASTAYS Subscription any time by visiting Your Account and
        adjusting your subscription settings on any of the ZATASTAYS Platforms. The following refund
        conditions apply:
      </p>
      <p>
        If you cancel within 5 business days of signing up, we will refund your full Subscription
        Fee For cancellations beyond 5 business days, we will deduct the subscription fees for the
        number of days used and initiate the remaining refund. For annual ZATASTAYS Subscription
        cancellations, we will refund your Subscription Fee only to the extent of your usage of the
        subscription plan. Refunds will be credited to your original payment method within 5 to 7
        business days.
      </p>
      <!-- </li>
      </ul> -->

      <h3>3. Revisions</h3>
      <p>
        We may in our discretion change the Terms of Service as well as this Cancellation & Refund
        Policy, or any aspect of ZATASTAYS Subscription, without notice to you. If any change to
        these terms is found invalid, void, or for any reason unenforceable, that change is
        severable and does not affect the validity and enforceability of any remaining changes or
        conditions.
      </p>

      <p class="font-bold">
        YOUR CONTINUED SUBSCRIPTION AFTER WE CHANGE THESE TERMS CONSTITUTES YOUR ACCEPTANCE OF THE
        CHANGES. IF YOU DO NOT AGREE TO ANY CHANGES, YOU MUST CANCEL YOUR SUBSCRIPTION.
      </p>

      <h3>4. Termination by Us</h3>
      <p>
        We may terminate your ZATASTAYS Subscription at our discretion without notice. If we do so:
      </p>
      <p>
        We will issue a credit note and give a prorated refund based on the remaining term of your
        subscription. No refund will be given for termination related to conduct that violates the
        Terms of Service, applicable law, involves fraud or misuse, or is harmful to our interests
        or another user.
      </p>
      <p>
        Our failure to insist upon or enforce your strict compliance with the Terms of Services or
        this Cancellation & Refund Policy will not constitute a waiver of any of our rights.
      </p>

      <h3>5. Limitation of Liability</h3>
      <p>
        In addition to other limitations and exclusions in conditions of use and sale, in no event
        will we or our directors, officers, employees, agents or other representatives be liable for
        any direct, indirect, special, incidental, consequential, or punitive damages, or any other
        damages of any kind, arising out of or related to ZATASTAYS platforms.
      </p>

      <p>
        Our total liability, whether in contract, warranty, tort (including negligence) or
        otherwise, will not exceed the last subscription fee you paid. These exclusions and
        limitations of liability will apply to the fullest extent permitted by law and will survive
        cancellation or termination of your ZATASTAYS subscription.
      </p>
    </el-row>
  </el-row>
</template>

<script>
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'CancellationPolicy',
  setup() {
    const { t } = useI18n()
    const base = reactive({})

    return {
      t,
      base
    }
  }
}
</script>

<style>
.plan-card {
  padding: 40px;
}
.pricing-heading {
  margin-bottom: 20px;
}
.price {
  text-align: center;
  margin-bottom: 20px;
}
.plan-name {
  text-align: center;
  margin-bottom: 15px;
}
.plan-btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.plan-feature-icon {
  margin-top: 6px;
  margin-right: 10px;
}
.privacy-policy-hero {
  padding: 5rem 0rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.privacy-policy-hero .el-row * {
  display: block;
}
.privacy-policy-hero .h3,
h4 {
  width: 100%;
}
.privacy-policy-hero p {
  width: 100%;
}
</style>
