<template>
  <el-container v-loading.fullscreen.lock="base.loading" class="landing-page">
    <el-header class="landing-nav">
      <el-row class="nav-container">
        <a href="#" class="logo">
          <img :src="zataLogo" width="50" height="50" alt="main_logo" />
          ZataStays
        </a>
        <el-row class="nav-links">
          <a href="#" @click="redirectTo('home')">{{ t('landingPage.home') }}</a>
          <a href="#" @click="redirectTo('pricing')">{{ t('landingPage.pricing') }}</a>
          <a href="#" @click="redirectTo('contactUs')">{{ t('landingPage.contact') }}</a>
          <a href="#" @click="redirectTo('login')">{{ t('landingPage.login') }}</a>
          <a href="#" class="landing-btn landing-btn-outline" @click="redirectTo('scheduleDemo')">
            {{ t('landingPage.scheduleDemo') }}
          </a>
          <a href="#" class="landing-btn landing-btn-primary" @click="redirectTo('signup')">
            {{ t('landingPage.startFreeTrial') }}
          </a>
        </el-row>
      </el-row>
    </el-header>
    <el-row v-if="base.currentTab === 'home'">
      <el-row class="hero">
        <el-col :span="14">
          <div class="hero-content">
            <h1>{{ t('landingPage.landingPageHeading') }}</h1>
            <div class="hero-buttons">
              <a href="#" class="btn btn-primary" @click="redirectTo('signup')">
                {{ t('landingPage.startFreeTrial') }}
              </a>
              <a href="#" class="btn btn-outline" @click="redirectTo('scheduleDemo')">
                {{ t('landingPage.scheduleADemo') }}
              </a>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="hero-image">
            <img src="../assets/landingPage/reservation.png" alt="Mobile App Interface" />
          </div>
        </el-col>
      </el-row>
      <el-row class="features">
        <!-- <el-col> -->
        <div class="features-container">
          <div class="features-header">
            <h2>
              {{ t('landingPageFeatures.title') }}
              <br />
              <span>ZataStays HMS</span>
            </h2>
            <p>
              {{ t('landingPageFeatures.description') }}
            </p>
          </div>

          <div class="features-grid">
            <div class="dashboard-preview">
              <img src="../assets/landingPage/dashboard.png" alt="Dashboard Interface" />
            </div>
            <el-row class="features-list">
              <el-col :span="12" class="feature-list-item">
                <el-row>
                  <el-col :span="4" class="feature-icon">
                    <el-row class="feature-icon-box">
                      <el-icon :size="35"><TrendCharts /></el-icon>
                    </el-row>
                  </el-col>
                  <el-col :span="20" class="feature-list-content">
                    <h5>{{ t('landingPageFeatures.insightsDashboard') }}</h5>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12" class="feature-list-item">
                <el-row>
                  <el-col :span="4" class="feature-icon">
                    <el-row class="feature-icon-box">
                      <el-icon :size="35"><Calendar /></el-icon>
                    </el-row>
                  </el-col>
                  <el-col :span="20" class="feature-list-content">
                    <h5>{{ t('landingPageFeatures.reservationManager') }}</h5>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12" class="feature-list-item">
                <el-row>
                  <el-col :span="4" class="feature-icon">
                    <el-row class="feature-icon-box">
                      <el-icon :size="35"><CreditCard /></el-icon>
                    </el-row>
                  </el-col>
                  <el-col :span="20" class="feature-list-content">
                    <h5>{{ t('landingPageFeatures.quickBillGenerator') }}</h5>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12" class="feature-list-item">
                <el-row>
                  <el-col :span="4" class="feature-icon">
                    <el-row class="feature-icon-box">
                      <el-icon :size="35"><UserFilled /></el-icon>
                    </el-row>
                  </el-col>
                  <el-col :span="20" class="feature-list-content">
                    <h5>{{ t('landingPageFeatures.employeeHub') }}</h5>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12" class="feature-list-item">
                <el-row>
                  <el-col :span="4" class="feature-icon">
                    <el-row class="feature-icon-box">
                      <el-icon :size="35"><KnifeFork /></el-icon>
                    </el-row>
                  </el-col>
                  <el-col :span="20" class="feature-list-content">
                    <h5>{{ t('landingPageFeatures.diningManager') }}</h5>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>

          <div style="text-align: center; margin-top: 3rem">
            <a href="#" class="btn btn-primary" @click="redirectTo('scheduleDemo')">
              {{ t('landingPageFeatures.bookDemoButton') }}
            </a>
          </div>
        </div>
        <!-- </el-col> -->
      </el-row>
      <el-row class="testimonials">
        <el-col>
          <h3>{{ t('testimonials.title') }}</h3>
          <el-row class="testimonial-grid" :gutter="20">
            <el-col :span="12">
              <el-row class="testimonial-card">
                <p>
                  {{ t('testimonials.text1') }}
                </p>
                <div class="author">{{ t('testimonials.author1') }}</div>
              </el-row>
            </el-col>
            <el-col :span="12">
              <el-row class="testimonial-card">
                <p>
                  {{ t('testimonials.text2') }}
                </p>
                <div class="author">{{ t('testimonials.author2') }}</div>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-row>
    <el-row v-else-if="base.currentTab === 'login'">
      <el-col class="login-content">
        <div class="login-container">
          <div class="login-header">
            <h1 class="login-title">{{ t('landingPageLogin.title') }}</h1>
            <p class="login-subtitle">{{ t('landingPageLogin.subtitle') }}</p>
          </div>
          <el-form label-position="top">
            <div class="form-group">
              <label for="email" class="form-label">{{ t('emailLabel') }}</label>
              <el-input
                v-model="base.email"
                class="login-input"
                size="large"
                placeholder="Login"
                autocomplete="off"
              >
                <template #prefix>
                  <i class="fa-solid fa-user"></i>
                </template>
              </el-input>
            </div>
            <div class="form-group">
              <label for="password" class="form-label">{{ t('passwordLabel') }}</label>
              <el-input
                v-model="base.password"
                class="login-input"
                size="large"
                autocomplete="off"
                type="password"
                placeholder="Password"
                show-password
              >
                <template #prefix>
                  <i class="fa-solid fa-lock"></i>
                </template>
              </el-input>
            </div>
            <el-button
              class="w-100 btn-lg text-sm login-button"
              type="primary"
              @click="handleLogin"
            >
              {{ t('landingPageLogin.signInButton') }}
            </el-button>
            <div class="form-footer">
              {{ t('landingPageLogin.noAccountText') }}
              <a href="#" class="link primary-color" @click="redirectTo('signup')">
                {{ t('landingPageLogin.startFreeTrialLink') }}
              </a>
            </div>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <el-row v-else-if="base.currentTab === 'signup'">
      <el-col class="signup-content">
        <div class="signup-container">
          <div class="signup-header">
            <h1 class="signup-title">{{ t('landingPageSignup.title') }}</h1>
            <p class="signup-subtitle">{{ t('landingPageSignup.subtitle') }}</p>
          </div>
          <el-row v-if="base.activeFormIndex === 0">
            <el-col :span="24">
              <p class="text-uppercase text-sm fw700">
                {{ t('landingPageSignup.hotelInfoSection') }}
              </p>
            </el-col>
          </el-row>
          <el-form
            v-if="base.activeFormIndex === 0"
            ref="hotelDetailsRef"
            :model="base.hotelForm"
            :rules="base.hotelDetailsRules"
            label-position="top"
          >
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item :label="$t('hotelName')" prop="name">
                  <el-input v-model="base.hotelForm.name" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('mobileNumber')" prop="mobile">
                  <el-input
                    v-model="base.hotelForm.mobile"
                    maxlength="10"
                    size="large"
                    autocomplete="off"
                    @input="
                      () => {
                        base.hotelForm.mobile = base.hotelForm.mobile.replace(/[^0-9]/g, '')
                      }
                    "
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('email')" prop="emailId">
                  <el-input v-model="base.hotelForm.emailId" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('address')" prop="address">
                  <el-input v-model="base.hotelForm.address" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('website')" prop="website">
                  <el-input v-model="base.hotelForm.website" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('plans')">
                  <el-select
                    v-model="base.plan.name"
                    :placeholder="$t('common.select')"
                    size="large"
                  >
                    <el-option
                      v-for="item in base.plans"
                      :key="item.name"
                      :label="item.displayName"
                      :value="item.name"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('checkInTime')">
                  <el-time-picker
                    v-model="base.hotelForm.checkInTime"
                    arrow-control
                    size="large"
                    class="w-100"
                    :placeholder="$t('common.pickATime')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('checkOutTime')">
                  <el-time-picker
                    v-model="base.hotelForm.checkOutTime"
                    arrow-control
                    size="large"
                    class="w-100"
                    :placeholder="$t('common.pickATime')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('gstNumber')" prop="config.gstNumber">
                  <el-input
                    v-model="base.hotelForm.config.gstNumber"
                    size="large"
                    autocomplete="off"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item :label="$t('hotelLogo')" prop="config.hotelLogo.base64String">
                  <el-col v-if="base.hotelForm.config.hotelLogo?.base64String">
                    <img
                      :src="`data:${base.hotelForm.config.hotelLogo?.mimeType};base64,${base.hotelForm.config.hotelLogo?.base64String}`"
                      width="150"
                    />
                  </el-col>
                  <el-col v-if="base.hotelForm.config.hotelLogo?.base64String">
                    <el-upload
                      class="upload-demo mt10"
                      :show-file-list="false"
                      :on-remove="handleRemove"
                      :http-request="handleUploadRequest"
                      :on-change="handleChange"
                    >
                      <el-button type="primary" class="w-100">Click to Re-Upload</el-button>
                    </el-upload>
                    <el-button
                      class="mt10 download-button"
                      type="default"
                      @click="downloadBase64File"
                    >
                      {{ $t('reservations.clickToDownload') }}
                    </el-button>
                  </el-col>
                  <el-upload
                    v-if="!base.hotelForm.config.hotelLogo?.base64String"
                    class="upload-demo w-100"
                    drag
                    :show-file-list="false"
                    :on-remove="handleRemove"
                    :http-request="handleUploadRequest"
                    :on-change="handleChange"
                  >
                    <i class="fa-solid fa-cloud-arrow-up upload-icon-custom"></i>
                    <div class="el-upload__text">
                      {{ $t('reservations.dropFileHere') }}
                      <em>{{ $t('reservations.clickToUpload') }}</em>
                    </div>
                    <template #tip>
                      <el-row>
                        <el-col :span="12">
                          <div class="el-upload__tip text-sm">
                            {{ $t('hotelDetails.uploadTip') }}
                          </div>
                        </el-col>
                      </el-row>
                    </template>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row v-if="base.activeFormIndex === 1">
            <el-col :span="24">
              <p class="text-uppercase text-sm">{{ t('landingPageSignup.userInfoSection') }}</p>
            </el-col>
          </el-row>
          <el-form v-if="base.activeFormIndex === 1" :model="base.userForm" label-position="top">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item :label="t('title')">
                  <el-select
                    v-model="base.userForm.title"
                    :placeholder="$t('common.select')"
                    size="large"
                  >
                    <el-option
                      v-for="item in base.titleOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('firstName')">
                  <el-input v-model="base.userForm.firstName" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('middleName')">
                  <el-input v-model="base.userForm.middleName" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('lastName')">
                  <el-input v-model="base.userForm.lastName" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('email')">
                  <el-input v-model="base.userForm.email" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('password')">
                  <el-input
                    v-model="base.userForm.password"
                    class="login-input"
                    size="large"
                    autocomplete="off"
                    type="password"
                    placeholder="Password"
                    show-password
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('gender')">
                  <el-select
                    v-model="base.userForm.gender"
                    :placeholder="$t('common.select')"
                    size="large"
                  >
                    <el-option
                      v-for="item in base.genderOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('mobileNumber')">
                  <el-input
                    v-model="base.userForm.mobileNumber"
                    maxlength="10"
                    size="large"
                    autocomplete="off"
                    @input="
                      () => {
                        base.userForm.mobileNumber = base.userForm.mobileNumber.replace(
                          /[^0-9]/g,
                          ''
                        )
                      }
                    "
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('alternateNumber')">
                  <el-input
                    v-model="base.userForm.alternateNumber"
                    maxlength="10"
                    size="large"
                    autocomplete="off"
                    @input="
                      () => {
                        base.userForm.alternateNumber = base.userForm.alternateNumber.replace(
                          /[^0-9]/g,
                          ''
                        )
                      }
                    "
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('address')">
                  <el-input v-model="base.userForm.address" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('country')">
                  <el-select
                    v-model="base.userForm.country"
                    :placeholder="$t('common.select')"
                    size="large"
                    filterable
                    value-key="country"
                    @change="handleSetStates"
                  >
                    <el-option
                      v-for="item in base.countries"
                      :key="item.country"
                      :label="item.country"
                      :value="item.country"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('state')">
                  <el-select
                    v-model="base.userForm.state"
                    filterable
                    :placeholder="$t('common.select')"
                    size="large"
                  >
                    <el-option
                      v-for="item in base.states"
                      :key="item"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('city')">
                  <el-input v-model="base.userForm.city" size="large" autocomplete="off" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="t('zipCode')">
                  <el-input
                    v-model="base.userForm.zipCode"
                    maxlength="8"
                    size="large"
                    autocomplete="off"
                    @input="
                      () => {
                        base.userForm.zipCode = base.userForm.zipCode.replace(/[^0-9]/g, '')
                      }
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-row justify="end" :gutter="20">
            <el-col v-if="base.activeFormIndex === 1" :span="4">
              <el-button class="w-100" @click="base.activeFormIndex -= 1">
                {{ t('previous') }}
              </el-button>
            </el-col>
            <el-col :span="4">
              <el-button class="w-100" type="primary" @click="handleNext">
                {{ base.activeFormIndex === 1 ? t('submit') : t('next') }}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row v-else-if="base.currentTab === 'pricing'">
      <pricing></pricing>
    </el-row>
    <!-- <el-row v-else-if="base.currentTab === 'privacyPolicy'">
      <privacy-policy></privacy-policy>
    </el-row> -->
    <!-- <el-row v-else-if="base.currentTab === 'refundPolicy'">
      <refund-policy></refund-policy>
    </el-row> -->
    <el-row v-else-if="base.currentTab === 'contactUs'">
      <contact-us></contact-us>
    </el-row>
    <!-- <el-row v-else-if="base.currentTab === 'termsAndConditions'">
      <terms-and-conditions></terms-and-conditions>
    </el-row> -->
    <el-row v-else-if="base.currentTab === 'subscriptionExpired'">
      <SubscriptionExpired></SubscriptionExpired>
    </el-row>
    <el-row v-else-if="base.currentTab === 'scheduleDemo'">
      <zatastays-demo></zatastays-demo>
    </el-row>
    <router-view></router-view>
    <footer v-if="base.currentTab !== 'subscriptionExpired'" class="landing-page-footer">
      <p>
        {{ t('footer.contactUs') }}:
        <a class="white" href="mailto:info@ZataStays.com">info@ZataStays.com</a>
      </p>
      <p>&copy; 2024 ZT Property Management System. {{ t('footer.copyright') }}</p>
    </footer>
    <el-row class="copyright-text">
      <el-col :span="12" class="mt20 mb20">ZataStays. All Rights Reserved.</el-col>
      <el-col :span="12" class="tr mt20 mb20">
        <a href="#" @click="redirectTo('privacyPolicy')">Privacy Policy</a>
        <el-divider direction="vertical" />
        <a href="#" @click="redirectTo('termsAndConditions')">Terms & Conditions</a>
        <el-divider direction="vertical" />
        <a href="#" @click="redirectTo('refundPolicy')">Cancellation & Refund Policy</a>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import { ref, onBeforeMount, onBeforeUnmount, onMounted, reactive, watch } from 'vue'
import { ElNotification } from 'element-plus'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { TrendCharts, Calendar, CreditCard, UserFilled, KnifeFork } from '@element-plus/icons-vue'
import _ from 'lodash'
import { useI18n } from 'vue-i18n'

import AuthSvc from '@/services/auth'
import common from '@/utils/common'
import constants from '@/constants'
import zataLogo from '@/assets/logo/logo1.png'
import SubscriptionExpired from './components/SubscriptionExpired.vue'
import Pricing from './components/Pricing.vue'
// import PrivacyPolicy from './components/PrivacyPolicy.vue'
// import RefundPolicy from './components/RefundPolicy.vue'
// import TermsAndConditions from './components/TermsAndConditions.vue'
import ContactUs from './components/ContactUs.vue'
import ZatastaysDemo from './components/ZatastaysDemo.vue'

export default {
  name: 'LandingPage',
  components: {
    TrendCharts,
    Calendar,
    CreditCard,
    UserFilled,
    KnifeFork,
    SubscriptionExpired,
    Pricing,
    // PrivacyPolicy,
    // RefundPolicy,
    // TermsAndConditions,
    ContactUs,
    ZatastaysDemo
  },
  setup() {
    const hotelDetailsRef = ref(null)
    const route = useRoute()
    const { t } = useI18n()
    const store = useStore()
    const router = useRouter()
    const base = reactive({
      currentTab: 'home',
      loading: false,
      email: '',
      password: '',
      activeFormIndex: 0,
      userForm: {
        email: '',
        password: '',
        title: '',
        firstName: '',
        lastName: '',
        middleName: '',
        gender: 'male',
        roles: ['Superuser'],
        defaultRole: 'Superuser',
        mobileNumber: '',
        alternateNumber: '',
        address: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        isPlatformAdministrator: false
      },
      hotelForm: {
        name: '',
        emailId: '',
        mobile: '',
        website: '',
        address: '',
        checkInTime: '',
        checkOutTime: '',
        config: {
          gstNumber: '',
          hotelLogo: {},
          defaultLanguage: 'en-US'
        }
      },
      titleOptions: constants.TITLE_OPTIONS,
      genderOptions: constants.GENDER_OPTIONS,
      countries: constants.COUNTRIES,
      states: [],
      plans: [
        {
          name: 'ZATA Restaurants',
          displayName: 'ZATA Restaurants'
        },
        {
          name: 'ZATA Basic',
          displayName: 'ZATA Basic'
        },
        {
          name: 'ZATA Pro',
          displayName: 'ZATA Pro'
        }
      ],
      plan: {
        name: 'ZATA Pro'
      },
      hotelDetailsRules: {
        name: [{ required: true, message: ' ', trigger: 'blur' }],
        emailId: [{ required: true, message: ' ', trigger: 'blur' }],
        mobile: [{ required: true, message: ' ', trigger: 'blur' }],
        website: [{ required: true, message: ' ', trigger: 'blur' }],
        address: [{ required: true, message: ' ', trigger: 'blur' }],
        checkInTime: [{ required: true, message: ' ', trigger: 'blur' }],
        checkOutTime: [{ required: true, message: ' ', trigger: 'blur' }],
        'config.gstNumber': [{ required: true, message: ' ', trigger: 'blur' }],
        'config.defaultLanguage': [{ required: true, message: ' ', trigger: 'blur' }]
      }
    })

    onMounted(() => {
      base.hotelForm.checkInTime = getDateWithTime(11, 0)
      base.hotelForm.checkOutTime = getDateWithTime(14, 0)
      setCurrentTab(route)
    })

    const getDateWithTime = (hours, minutes) => {
      const now = new Date() // Get current date
      now.setHours(hours, minutes, 0, 0) // Set specific hours and minutes
      return now
    }

    watch(
      () => route,
      (to, from) => {
        if (to.name !== from.name) {
          console.log('to', to)
          setCurrentTab(to)
        }
      },
      { deep: true }
    )

    const redirectTo = page => {
      const pages = ['privacyPolicy', 'termsAndConditions', 'refundPolicy']
      if (pages.includes(page)) {
        router.push(`/home/${page}`)
        base.currentTab = ''
      } else {
        base.currentTab = page
        router.push('/')
      }
    }

    const setCurrentTab = route => {
      if (route.name) {
        base.currentTab = route.name
      }
    }

    const handleLogin = () => {
      const payload = {
        login: base.email,
        password: base.password
      }
      AuthSvc.login(payload)
        .then(() => {
          router.push('/dashboard')
        })
        .catch(error => {
          console.log('Sign in handleLogin error => ', error)
          common.showErrors(error)
        })
    }
    const handleSetStates = () => {
      if (base.userForm.country) {
        const country = constants.COUNTRIES.find(f => f.country === base.userForm.country)
        base.states = country?.states || []
      } else {
        base.states = []
      }
    }

    const handleNext = () => {
      if (base.activeFormIndex === 0) {
        hotelDetailsRef.value.validate(valid => {
          if (valid) {
            base.activeFormIndex = 1
          } else {
            common.showMandatoryValidation()
          }
        })
      } else {
        const payload = {
          subscriptionName: _.camelCase(base.hotelForm.name),
          plan: base.plan,
          tenant: base.hotelForm,
          user: base.userForm
        }
        payload.tenant.code = payload.subscriptionName
        payload.user.login = payload.user.email
        AuthSvc.signup(payload)
          .then(() => {
            ElNotification({
              message: 'User Registered successfully. Please sign in to continue!',
              type: 'success'
            })
            redirectTo('home')
          })
          .catch(error => {
            console.log('Error signup user', error)
            common.showErrors(error)
          })
      }
    }

    const handleRemove = () => {
      base.hotelForm.config.hotelLogo = {}
    }

    const handleUploadRequest = () => {
      console.log('uploading..')
    }

    const handleChange = file => {
      base.loading = true
      convertToBase64(file.raw)
    }

    const convertToBase64 = file => {
      if (!file) {
        base.loading = false
        // this.$message.error('Please select a file first!')
        return
      }

      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1] // Extract Base64 part
        // console.log('base64String', base64String)
        base.hotelForm.config.hotelLogo = {
          base64String,
          name: file.name,
          size: file.size,
          mimeType: file.type
        }
        ElNotification({
          message: 'File uploaded successfully!',
          type: 'success'
        })
        base.loading = false
      }
      reader.onerror = () => {
        // this.$message.error('Error reading file')
        base.loading = false
      }

      reader.readAsDataURL(file)
    }

    const downloadBase64File = () => {
      if (base.hotelForm.config.hotelLogo?.base64String) {
        const base64String = `data:${base.hotelForm.config.hotelLogo.mimeType};base64,${base.hotelForm.config.hotelLogo.base64String}`
        // Create a temporary anchor element
        const link = document.createElement('a')

        // Set the href to the Base64 string and specify the file type in the data URL
        link.href = base64String
        link.download = base.hotelForm.config.hotelLogo.name // Set the desired file name

        // Append the link to the document and trigger a click event to download
        document.body.appendChild(link)
        link.click()

        // Remove the link after download
        document.body.removeChild(link)
      }
    }

    onBeforeMount(() => {
      store.state.hideConfigButton = true
      store.state.showNavbar = false
      store.state.showSidenav = false
      store.state.showFooter = false
    })

    onBeforeUnmount(() => {
      store.state.hideConfigButton = false
      store.state.showNavbar = true
      store.state.showSidenav = true
      store.state.showFooter = true
    })

    return {
      t,
      base,
      hotelDetailsRef,
      handleLogin,
      handleSetStates,
      handleNext,
      zataLogo,
      redirectTo,
      handleRemove,
      handleUploadRequest,
      handleChange,
      downloadBase64File
    }
  }
}
</script>

<style>
:root {
  --primary: #00a3ff;
  --light-primary: #ebf8ff;
  --dark: #000000;
  --light: #ffffff;
  --gray: #f5f5f5;
}
.landing-page {
  background: #f8fafc !important;
}
.landing-nav {
  padding: 5px 20px !important;
  position: fixed;
  /* top: 0; */
  width: 100%;
  background: var(--light);
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary);
}

.logo svg {
  width: 40px;
  height: 40px;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: var(--dark);
}

.landing-btn {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.landing-btn-outline {
  border: 2px solid var(--primary);
  color: var(--primary);
  background: transparent;
}

.landing-btn-primary {
  background: var(--primary);
  color: var(--light);
  border: none;
}

.hero {
  padding: 7rem 0rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.hero-content h1 {
  font-size: 2.5rem !important;
  margin-bottom: 1.5rem;
  color: var(--dark);
}

.hero-buttons {
  display: flex;
  gap: 1rem;
}

.hero-image img {
  width: 100%;
  max-width: 600px;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.features {
  padding: 4rem 2rem;
  background: var(--light-primary);
  margin: 0 auto;
  max-width: 1200px;
  align-items: center;
}

/* .features-container {
  max-width: 1200px;
  margin: 0 auto;
} */

.features-header {
  text-align: center;
  margin-bottom: 3rem;
}

.features-header h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--dark);
}

.features-header span {
  color: var(--primary);
}

.features-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.dashboard-preview {
  background: var(--light);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dashboard-preview img {
  width: 100%;
  border-radius: 0.5rem;
}

.feature-item {
  text-align: center;
}

.feature-list-item {
  margin-bottom: 20px;
}

.feature-list-content {
  display: flex !important;
  align-items: center;
  padding-left: 10px;
  flex-wrap: wrap;
}

.feature-icon-box {
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-icon svg {
  color: var(--primary);
}

.testimonials {
  background: var(--light) !important;
  padding: 4rem 0rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}

.testimonials h3 {
  margin-bottom: 3rem;
}

.testimonial-card {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.testimonial-card p {
  font-style: italic;
  margin-bottom: 1rem;
  color: #444;
}

.testimonial-card .author {
  font-weight: bold;
  color: var(--primary-blue);
}

.landing-page-footer {
  background: var(--dark);
  color: white;
  padding: 2rem 5%;
  text-align: center;
}

.login-button {
  font-weight: 600 !important;
  padding: 1rem 2rem !important;
}

.login-content {
  margin-top: 4rem;
  padding: 2rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 5rem);
}

.login-container {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  max-width: 400px;
}

.login-header {
  text-align: center;
  margin-bottom: 2rem;
}

.login-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

.login-subtitle {
  color: #6b7280;
  font-size: 0.875rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.form-footer {
  margin-top: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  color: #6b7280;
}

.primary-color {
  color: var(--primary);
  text-decoration: none;
  font-weight: 600;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #333 !important;
  transition: background-color 5000s ease-in-out 0s;
}

.el-input__inner:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media (min-width: 1400px) {
  .login-container {
    max-width: 1320px;
  }
}

@media (min-width: 1200px) {
  .login-container {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .login-container {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .login-container {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .login-container {
    max-width: 540px;
  }
}

/* Signup Style Start */
.signup-content {
  margin-top: 4rem;
  padding: 2rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 5rem);
}
.signup-container {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  max-width: 1200px;
}
.signup-header {
  text-align: center;
  margin-bottom: 2rem;
}
.signup-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}
.signup-subtitle {
  color: #6b7280;
  font-size: 0.875rem;
}
/* Signup Style End */
.copyright-text {
  min-width: 1200px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.white {
  color: white !important;
}
</style>
