<template>
  <el-row class="contact-us privacy-policy-hero w-100">
    <el-col class="text-center">
      <h2 class="pricing-heading">Demo</h2>
    </el-col>
    <el-row class="demo-iframe w-100">
      <iframe
        src="https://youtube.com/embed/R8OybNh3Xng"
        width="560"
        height="315"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </el-row>
  </el-row>
</template>

<script>
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'PrivacyPolicy',
  setup() {
    const { t } = useI18n()
    const base = reactive({})

    return {
      t,
      base
    }
  }
}
</script>

<style>
.contact-us {
  min-height: calc(100vh - 214px);
  max-height: calc(100vh - 214px);
}
.plan-card {
  padding: 40px;
}
.pricing-heading {
  margin-bottom: 20px;
}
.price {
  text-align: center;
  margin-bottom: 20px;
}
.plan-name {
  text-align: center;
  margin-bottom: 15px;
}
.plan-btn {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.plan-feature-icon {
  margin-top: 6px;
  margin-right: 10px;
  /* color: #00a3ff; */
}
.privacy-policy-hero {
  padding: 5rem 0rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.privacy-policy-hero .el-row * {
  display: block;
}
.privacy-policy-hero .h3,
h4 {
  width: 100%;
}
.privacy-policy-hero p {
  width: 100%;
}
.demo-iframe {
  justify-content: center;
}
</style>
